/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';

// import ui libraries

// assets

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Dropdown({ subLinks, dropdown, hasDropDown, linkId }) {
  // localisation

  /* ----------------------------- LOCAL VARIABLES ---------------------------- */

  /* ---------------------------------- HOOKS --------------------------------- */

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  /* ******************************** RENDERING ******************************* */
  return (
    <ul
      className={
        hasDropDown && dropdown.id === linkId && dropdown.isDroped
          ? 'subLinks-submenu'
          : 'subLinks-submenu hidden'
      }
    >
      {subLinks?.map((sublink) => (
        <li key={sublink.id} className="sublink-item">
          <Link
            to={sublink.url}
            className={`${sublink?.subsublinks?.length > 0 ? 'arrow' : ''}`}
          >
            {sublink.name}
          </Link>

          {/* Check if the sublink has subsublinks */}
          {sublink.subsublinks && sublink.subsublinks.length > 0 && (
            <ul className="subsublinks-submenu">
              {sublink.subsublinks.map((subsublink) => (
                <li key={subsublink.id} className="subsublink-item">
                  <Link to={subsublink.url}>{subsublink.name}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}

Dropdown.propTypes = {
  subLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      subsublinks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  linkId: PropTypes.number.isRequired,
  hasDropDown: PropTypes.bool.isRequired,
  dropdown: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isDroped: PropTypes.bool.isRequired,
  }).isRequired,
};

Dropdown.defaultProps = {
  subLinks: [],
};

export default Dropdown;
