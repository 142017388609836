/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents

// Local UI components
import Carousel from 'react-elastic-carousel';
import SubTitle from '../../../shared/UIKit/SubTitle';
import SliderItem from '../../../shared/UIKit/SliderItem';

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Programs() {
  /* ******************************** CONSTANTS ******************************* */
  // carousel props
  const CrouselSettings = {
    showArrows: true,
    pagination: false,
    enableAutoPlay: true,
    autoPlaySpeed: 5000,
    breakPoints: [
      { Width: 500, itemsToShow: 1, itemPadding: [0, 10] },
      { width: 850, itemsToShow: 1.2, itemPadding: [0, 20] },
    ],
  };
  /* ******************************** HOOKS ******************************* */
  const CarouselEl = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  // Localization
  const { t } = useTranslation();
  const { title, sliders } = t('programs', {
    returnObjects: true,
  });

  /* ******************************** RENDER HELPERS ******************************* */

  const programs = sliders.map(({ id, ...program }) => (
    <SliderItem key={id} ProgramData={program} />
  ));

  function NavigationLines() {
    return sliders.map((_, index) => {
      return (
        <button
          type="button"
          className={`${index === activeSlide ? 'active' : ''}`}
          onClick={() => {
            setActiveSlide(index);
            CarouselEl.current.goTo(index);
          }}
        >
          {index + 1}
        </button>
      );
    });
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <section className="programs">
      <span id="programs-section" />
      <div className="container scroll-reveal">
        <SubTitle>{title}</SubTitle>
        <div className="carousel-container">
          <div className="slider-navigation">
            <NavigationLines />
          </div>
          <Carousel
            {...CrouselSettings}
            ref={CarouselEl}
            onChange={(_, pageIndex) => setActiveSlide(pageIndex)}
            renderArrow={({ type, onClick, isEdge }) => {
              return (
                <button
                  type="button"
                  onClick={onClick}
                  disabled={isEdge}
                  className={`programs-chevron ${
                    type === 'PREV' ? 'left-chevron' : ''
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707" />
                  </svg>
                </button>
              );
            }}
          >
            {programs}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

Programs.propTypes = {};

Programs.defaultProps = {};

export default Programs;
